import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import Modal from "../components/modal";
import EstimateForm from "../components/Estimate";
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import faqData from '../components/faqData';

const FaqPage = () => {
  const [isOpen, setIsOpen] = useState({});
  
  const toggleAccordion = (id) => {
    setIsOpen(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  return (
    <div>
      <Banner message="LICENSED | BONDED  | INSURED  | ROC #349206" />
      <Header />
      <div className=" overflow-y-scroll flex flex-col justify-center items-center">
        <div className="-mt-24 text-center min-h-screen w-3/4">
          <h1 className="text-2xl font-weight-500 font-semibold GtRegular mt-44">FAQ</h1>
          <p className="text-m GtRegular mt-4 mb-8 "> Have a question we haven't answered? Please give us a call! </p>
          {faqData.map((item) => (
            <div key={item.id} className="faq-item bg-white text-blue-800 items-center rounded-lg shadow-md mt-4">
              <div className="faq-question GtRegular flex-center" onClick={() => toggleAccordion(item.id)}>
                <div className="py-4 flex justify-center items-center">
                  <div className="ml-4 mr-4"> {isOpen[item.id] ? <FaChevronUp /> : <FaChevronDown />}</div>
                  <div>{item.question}</div>
                </div>
              </div>
              {isOpen[item.id] && (
                <div className="py-4 faq-answer font-mono text-center">
                  {item.answer}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FaqPage;
