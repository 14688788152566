import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Banner from '../components/Banner';
import { Link } from 'react-router-dom';
import Modal from '../components/modal';
import EstimateForm from '../components/Estimate';

import Portfolio from '../components/portfolio'; // Import the Portfolio component
import PortfolioCard from '../components/PortfolioCard';
import vanPic from '../gallery/van.jpeg';

export default function Home() {
  const [showFormModal, setShowFormModal] = useState(false);

  const toggleFormModal = () => {
    setShowFormModal(!showFormModal);
  };

  return (
    <>
      <Banner message="LICENSED | BONDED  | INSURED  | ROC #349206" />

      <Header onFormButtonClick={toggleFormModal} />

      <div className="overflow-y-scroll flex flex-col justify-center items-center h-full">
  <h1 className="text-4xl font-weight-600 text-blue-950 font-bold GtRegularBold">Our Work</h1>
  <p className="text-xl font-weight-300 GtRegular w-1/2 text-center">Filter through our different types of work we specialize in</p>
        <div className="text-center min-h-screen w-1/2">
          
 <Portfolio /> {/* Render the Portfolio component */}


        </div>
        <Footer className="font-mono" />
       

      </div>

      {/* Render EstimateForm component inside a modal */}
      {showFormModal && (
        <Modal onClose={toggleFormModal}>
          <EstimateForm />
        </Modal>
      )}
    </>
  );
}
