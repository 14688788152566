export default function Footer() {
    return (
      <footer className=" py-4 w-full">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between font-mono items-center h-24">
            <div className="text-black GtRegular">
              Arizona License ROC #349206
            </div>
            <div className="text-black GtRegular">
              © 2024 JAMI Painting. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    );
  }
  