const faqData = [
  {
    id: 1,
    question: "How do you determine the cost of a painting project?",
    answer: "We determine the cost based on the size of the area to be painted, the type of paint and materials used, and any additional services required, such as surface preparation and repairs."
  },
  {
    id: 2,
    question: "What kind of preparation is involved before painting begins?",
    answer: "Preparation involves cleaning the surfaces, sanding, patching holes, and applying primer. Proper preparation ensures a smooth and long-lasting finish."
  },
  {
    id: 3,
    question: "What types of paint do you use and are they environmentally friendly?",
    answer: "We use high-quality paints from reputable brands. We offer environmentally friendly options that are low in VOCs (Volatile Organic Compounds) to ensure a safer environment for you and your family."
  },
  {
    id: 4,
    question: "How long does a typical painting project take to complete?",
    answer: "The duration of a painting project depends on the size and complexity of the job. A typical project can take anywhere from a few days to a couple of weeks."
  },
  {
    id: 5,
    question: "Do you provide a warranty or guarantee for your work?",
    answer: "Yes, we provide a warranty for our painting services to ensure your satisfaction. The specific terms of the warranty will be discussed with you before the project begins."
  },
  {
    id: 6,
    question: "Can you help with color selection and design advice?",
    answer: "Absolutely! We offer color consultation services to help you choose the perfect colors and finishes for your project."
  },
  {
    id: 7,
    question: "How do you handle clean-up after the painting job is done?",
    answer: "We ensure that your property is left clean and tidy. All materials and debris will be removed, and furniture will be placed back in its original position."
  },
  {
    id: 8,
    question: "Are you licensed and insured?",
    answer: "Yes, we are fully licensed and insured to provide painting services. This ensures that you are protected in the unlikely event of an accident or damage."
  },
  {
    id: 9,
    question: "Do you offer services for both residential and commercial properties?",
    answer: "Yes, we offer painting services for both residential and commercial properties. No job is too big or too small for our experienced team."
  }
];

export default faqData;
