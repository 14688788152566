import { Link } from 'react-router-dom';

export default function Header() {
  return (
    <nav className="text-white GtRegularBold">
      <div className="flex items-center justify-between h-24 px-4">
        <div className="flex items-center GtRegularBold">
          <Link to="/home" className="px-3 py-2 text-xl md:text-2xl rounded-md font-bold text-white GtRegularBold">
            JAMI PAINTING LLC
          </Link>
        </div>
        <div className="md:hidden">
          {/* Hamburger icon for mobile */}
          <button className="text-white focus:outline-none">
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </button>
        </div>
        <div className="hidden md:block font-bold text-white GtRegularBold">
          <Link to="/home" className="px-3 py-2 rounded-md GtRegularBold hover:bg-blue-200">HOME</Link>
          <Link to="/about" className="px-3 py-2 rounded-md hover:bg-blue-200">ABOUT</Link>
          <Link to="/portfolio" className="px-3 py-2 rounded-md hover:bg-blue-200">PORTFOLIO</Link>
          <Link to="/services" className="px-3 py-2 rounded-md hover:bg-blue-200">SERVICES</Link>
          <Link to="/faqs" className="px-3 py-2 rounded-md hover:bg-blue-200">FAQs</Link>
        </div>
      </div>
    </nav>
  );
}
