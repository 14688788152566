import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import { Link } from 'react-router-dom';
import Modal from "../components/modal";
import EstimateForm from "../components/Estimate"; 
// Import the EstimateForm component

import Bucket from '../bucket.png';
import Brush from '/Users/irvinglamadrid/websites/jami painting/src/brush.png';

import ServiceCard from "../components/ServiceCard";


import pic2 from "../gallery/cabinets.jpeg";
import pic3 from "../gallery/color.jpeg";
import pic4 from "../gallery/epoxy.jpeg";
import pic5 from "../gallery/painting.jpeg";



export default function Home() {
  const [showFormModal, setShowFormModal] = useState(false);

  const toggleFormModal = () => {
    setShowFormModal(!showFormModal);
  };

  return (
    <>
      <Banner message="LICENSED | BONDED  | INSURED  | ROC #349206" />
      <Header onFormButtonClick={toggleFormModal} />
      <div className="overflow-y-scroll flex flex-col justify-center items-center">        
        <div className="text-center min-h-screen w-2/3">
          <h1 className="text-4xl md:mb-12 md:text-6xl text-blue-950 font-bold GtRegularBold mt-24">Transform Your Space with Precision and Passion</h1>
          <p className="mt-4 mx-auto text-sm md:text-xl font-bold GtRegular  ">Welcome to JAMI Painting, where every stroke tells a story. Our expert team brings passion and precision to every project, turning your vision into reality. From vibrant interiors to striking exteriors, we specialize in bringing spaces to life with creativity and craftsmanship. </p>
          <div className="flex flex-row justify-center GtRegularBold mt-4">


          <button className="text-orange-500  text-md md:text-3xl  border-orange-500 border-2  mr-4 py-2 px-2 md:px-6 rounded-xl mt-4 hover:bg-gray-700 focus:outline-none focus:bg-gray-700" onClick={toggleFormModal}>
              Testimonials
            </button>

            <img src={Bucket} alt="Bucket" className='w-auto h-44 md:h-64' style={{ position: 'absolute', bottom: 0, left: 0 }} />
            <img src={Brush} alt="Brush" className='w-auto h-44 md:h-64' style={{ position: 'absolute', bottom: 0, right: 0 }} />

            <button className="text-white text-md md:text-3xl  bg-orange-500 py-2 px-6 rounded-xl mt-4 hover:bg-gray-700 focus:outline-none focus:bg-gray-700" onClick={toggleFormModal}>
              Free Estimate
            </button>

          </div>
        </div>
        <Footer className="font-barlow" />

        </div>
  
      {/* Render EstimateForm component inside a modal */}
      {showFormModal && (
        <Modal onClose={toggleFormModal}>
          <EstimateForm />
        </Modal>
      )}
    </>
  );
}
