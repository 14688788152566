import React from "react";

const serviceCard = ({ imageSrc, title, description }) => {
  return (
    <div className="flex  shadow-lg rounded-lg font-mono overflow-hidden mx-auto">
 
  <div className="w-full h-96 flex rounded-lg flex-col">
    
    <img className="p-4 w-full h-44 rounded-xl object-cover object-center" src={imageSrc} alt={title} />
   
    <div className=" p-4 -mt-4">
      <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
      <p className="mt-2 text-gray-600">{description}</p>
   
    </div>
  </div>



</div>

  );
};

export default serviceCard;