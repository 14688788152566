import React from "react";

function Banner(props) {
  return (
    <div className="bg-white h-12 m:h-12 items-center GtRegular text-sm font-semibold text-gray-900 text-bold py-3 flex justify-between">
      <div className="ml-10 flex items-center text-[8px] sm:text-sm">
        <p className="mx-auto">{props.message}</p>
      </div>
      <div className="flex text-[8px] sm:text-sm px-6">
        <div className="mr-2 flex items-center">
          <p className="ml-2">office: 6027034531</p>
        </div>
        <div className="flex items-center">
          <p className="sm:ml-0 md:ml-2">work: 6025357478</p>
        </div>
      </div>
    </div>
  );
}

export default Banner;
