import React, { useState } from 'react';
import PortfolioCard from './PortfolioCard'; // Import correct path to PortfolioCard component

import Project1 from "/Users/irvinglamadrid/websites/jami painting/src/gallery/projects/res-com-Suprise.png";
import Project2 from "/Users/irvinglamadrid/websites/jami painting/src/gallery/projects/res-com-dignity.png";
import Project3 from "/Users/irvinglamadrid/websites/jami painting/src/gallery/projects/res-com-Miguel.png";
import Project4 from "/Users/irvinglamadrid/websites/jami painting/src/gallery/projects/res-com-Tucson.png";
import Project5 from "/Users/irvinglamadrid/websites/jami painting/src/gallery/projects/res-com-chandler.png";
import Project6 from "/Users/irvinglamadrid/websites/jami painting/src/gallery/projects/res-int-Tim-cabinets.png";
import Project7 from "/Users/irvinglamadrid/websites/jami painting/src/gallery/projects/res-int-Kellly.png";
import Project8 from "/Users/irvinglamadrid/websites/jami painting/src/gallery/projects/res-com-glendale.png";
import Project9 from "/Users/irvinglamadrid/websites/jami painting/src/gallery/projects/res-ext.png";
import Project10 from "/Users/irvinglamadrid/websites/jami painting/src/gallery/projects/res-ext-random.png";
import Project11 from "/Users/irvinglamadrid/websites/jami painting/src/gallery/projects/res-ext-random2.png";
import Project12 from "/Users/irvinglamadrid/websites/jami painting/src/gallery/projects/res-ext-white.png";


const Portfolio = ({ projects }) => {

  const backgroundImageUrl = 'https://www.example.com/path/to/your/html/file.html';

  const [filters, setFilters] = useState([]);

  // Function to handle filter changes
  const handleFilterChange = (selectedFilter) => {
    if (filters.includes(selectedFilter)) {
      // If the filter is already selected, remove it
      setFilters(filters.filter((filter) => filter !== selectedFilter));
    } else {
      // If the filter is not selected, add it
      setFilters([...filters, selectedFilter]);
    }
  };

  // Sample projects with image sources
  const sampleProjects = [
    {
      id: 1,
      title: 'Suprise',
      description: 'Remodel',
      imageSrc: Project1, // Image source for project 1
      tags: ['tag1', 'tag4'] /* interior, commercial */
    },
    {
      id: 2,
      title: 'Chandler',
      description: 'Remodel',
      imageSrc: Project2, // Image source for project 2
      tags: ['tag1', 'tag3']
    },
    {
      id: 3,
      title: 'Mesa',
      description: 'New Build',
      imageSrc: Project3, // Image source for project 3
      tags: ['tag1', 'tag4']
    },
    {
      id: 4,
      title: 'Tuscon',
      description: 'Frames, Doors, Walls',
      imageSrc: Project4, // Image source for project 4
      tags: ['tag1', 'tag4']
    },
    {
      id: 5,
      title: 'Chandler',
      description: 'Remodel',
      imageSrc: Project5, // Image source for project 5
      tags: ['tag1', 'tag4']
    },
    {
      id: 6,
      title: 'Scottsdale',
      description: 'Cabinets, Remodel',
      imageSrc: Project6, // Image source for project 6
      tags: ['tag1', 'tag4']
    },
    {
      id: 7,
      title: 'Mesa',
      description: 'New build',
      imageSrc: Project7, // Image source for project 6
      tags: ['tag1', 'tag4']
    },
    {
      id: 8,
      title: 'Goodyear',
      description: 'New build',
      imageSrc: Project12, // Image source for project 6
      tags: ['tag3']
    },
    {
      id: 9,
      title: 'Glendale',
      description: 'New build',
      imageSrc: Project8, // Image source for project 6
      tags: ['tag4']
    },
    {
      id: 10,
      title: 'Deer Valley',
      description: 'New build',
      imageSrc: Project9, // Image source for project 6
      tags: ['tag4']
    },
    {
      id: 11,
      title: 'Fountain Hills',
      description: 'Remodel',
      imageSrc: Project10, // Image source for project 6
      tags: ['tag2']
    },
    {
      id: 12,
      title: 'Gilbert',
      description: 'Remodel',
      imageSrc: Project11, // Image source for project 6
      tags: ['tag2']
    }
  ];

  // Use sample projects if no projects prop is provided
  const allProjects = projects ? projects : sampleProjects;

  // Filter projects based on selected filters
  const filteredProjects = allProjects.filter((project) => {
    if (filters.length === 0) {
      return true; // If no filters are selected, show all projects
    } else {
      // Show projects that have all selected tags
      return filters.every((filter) => project.tags.includes(filter));
    }
  });

  return (
    <div>
      {/* Filter checkboxes */}
      <div className="flex flex-col lg:flex-row justify-center items-center GtRegular mt-4 ">
     
        <div className="flex flex-row items-center space-x-4 mr-4 ">
          <label className="checkbox-label h-10 bg-white rounded-lg py-2 px-2 flex flex-row">
            <input
              className="checkbox mr-2"
              style={{ accentColor: 'black' }}
              type="checkbox"
              onChange={() => handleFilterChange('tag1')}
              checked={filters.includes('tag1')}
            /> Interior
          </label>
          <label className="checkbox-label h-10 bg-white rounded-lg py-2 px-2 flex flex-row">
            <input
              className="checkbox mr-2"
              style={{ accentColor: 'black' }}
              type="checkbox"
              onChange={() => handleFilterChange('tag2')}
              checked={filters.includes('tag2')}
            /> Exterior
          </label>
      
        </div>

        <div className="flex flex-row space-x-4">
        <label className="checkbox-label h-10 bg-white rounded-lg py-2 px-2 flex flex-row">
            <input
              className="checkbox mr-2"
              style={{ accentColor: 'black' }}
              type="checkbox"
              onChange={() => handleFilterChange('tag3')}
              checked={filters.includes('tag3')}
            /> Residential
          </label>
        <label className="checkbox-label h-10 bg-white rounded-lg py-2 px-2 flex flex-row">
            <input
              className="checkbox mr-2"
              style={{ accentColor: 'black' }}
              type="checkbox"
              onChange={() => handleFilterChange('tag4')}
              checked={filters.includes('tag4')}
            /> Commercial
          </label>
          
          </div>

      </div>

      {/* Display filtered projects */}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
        {filteredProjects.map((project) => (
          <PortfolioCard key={project.id} project={project} />
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
