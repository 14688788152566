import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import { Link } from 'react-router-dom';
import Modal from "../components/modal";
import EstimateForm from "../components/Estimate"; // Import the EstimateForm component
import ServiceCard from "../components/ServiceCard";

import pic2 from "../gallery/cabinets.jpeg";
import pic3 from "../gallery/color.jpeg";
import pic4 from "../gallery/epoxy.jpeg";
import pic5 from "../gallery/painting.jpeg";
import vanPic from "/Users/irvinglamadrid/websites/jami painting/src/van1.png";

export default function Home() {
  const [showFormModal, setShowFormModal] = useState(false);

  const toggleFormModal = () => {
    setShowFormModal(!showFormModal);
  };

  return (
    <>
      <Banner message="LICENSED | BONDED  | INSURED  | ROC #349206" />

      <Header onFormButtonClick={toggleFormModal} />

      <div className=" overflow-y-scroll flex flex-col justify-center items-center">

        <div className="text-center min-h-screen w-2/3">
          <h1 className="text-4xl font-weight-600 text-blue-950 font-bold GtRegularBold ">About Us</h1>
          <p className="mt-4 mx-auto text-xl GtRegular">Welcome to JAMI Painting, where every stroke tells a severy brushstroke is a masterpiece</p>
         
     

          <div className="flex flex-col md:flex-row lg:flex-row font-mono mt-4">

          <img className="p-4 w-full md:-ml-40 lg:-ml-40 md:-mt-20 h-auto rounded-xl object-cover object-center" src={vanPic} />

            <button className="text-orange-500 text-2xl border-orange-500 GtRegularBold border-2 py-2 md:mt-24 px-6 h-12 rounded-lg  mr-4 hover:bg-orange-400 focus:outline-none focus:bg-orange-700">
              <Link to="/contact">FAQs</Link>
            </button>
            <button className="text-white text-2xl  bg-orange-500  md:mt-24 py-2 px-6 rounded-lg GtRegularBold h-12 w-96 rounded-m  hover:bg-blue-700 focus:outline-none focus:bg-orange-700" onClick={toggleFormModal}>
             Free Estimate
            </button>
          </div>
      


        </div>

        <Footer className="font-mono" />
        
       

   
        </div>
  
      {/* Render EstimateForm component inside a modal */}
      {showFormModal && (
        <Modal onClose={toggleFormModal}>
          <EstimateForm />
        </Modal>
      )}
    </>
  );
}
