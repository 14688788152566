import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import { Link } from 'react-router-dom';
import Modal from "../components/modal";
import EstimateForm from "../components/Estimate"; // Import the EstimateForm component
import ServiceCard from "../components/ServiceCard";

import pic2 from "../gallery/cabinets.jpeg";
import pic3 from "../gallery/color.jpeg";
import pic4 from "../gallery/epoxy.jpeg";
import PaintingServicePic from "../gallery/painting.jpeg";
import vanPic from "../gallery/van.jpeg";

export default function Home() {
  const [showFormModal, setShowFormModal] = useState(false);

  const toggleFormModal = () => {
    setShowFormModal(!showFormModal);
  };

  useEffect(() => {
    document.title = "Professional House Painters in Phoenix, Arizona - Residential & Commercial Services";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Looking for reliable house painters in Phoenix, Arizona? We offer top-quality residential and commercial painting services. Get a free quote today!"
      );
  }, []);

  return (
    <>
      <Banner message="LICENSED | BONDED  | INSURED  | ROC #349206" />

      <Header onFormButtonClick={toggleFormModal} />

      <div className="overflow-y-scroll flex flex-col justify-center items-center">
        <div className="-mt-24 text-center min-h-screen w-3/4">


          <h1 className="text-4xl font-weight-600 text-blue-950 font-bold GtRegularBold mt-44">
            Professional Painting Services in Arizona
          </h1>
          <h2 className="text-xl font-weight-300 GtRegular text-center">
            We take pride in all of our services
          </h2>



          
          <div className="flex flex-row text-sm font-mono justify-center mt-12">
            <div className="mr-4 w-2/3">





              <ServiceCard
                imageSrc={PaintingServicePic}
                title="Painting"
                alt="Professional house painting service"
                description="We offer professional painting services for both interior and exterior spaces, 
                bringing vibrancy and life to your walls and surfaces with precision and expertise."
              />

              
            </div>
            <div className="mr-4 w-2/3">
              <ServiceCard
                imageSrc={pic2}
                title="Cabinets"
                alt="Cabinet refinishing and painting service"
                description="Revamp your kitchen or bathroom cabinets with our refinishing and painting 
                solutions, giving them a fresh, modern look that revitalizes your space."
              />
            </div>
            <div className="mr-4 w-2/3">
              <ServiceCard
                imageSrc={pic4}
                title="Floor Coating"
                alt="Floor coating service"
                description="Protect and enhance your floors with our durable coating solutions,
                 providing a seamless and attractive finish that withstands heavy foot traffic and daily wear and tear."
              />
            </div>
            <div className="mr-4 w-2/3">
              <ServiceCard
                imageSrc={pic3}
                title="Color Choice"
                alt="Color choosing service"
                description="Rely on our color specialists to choose the colors for your next project."
              />


              
            </div>
          </div>
        </div>
        <Footer className="font-mono" />
      </div>

      {/* Render EstimateForm component inside a modal */}
      {showFormModal && (
        <Modal onClose={toggleFormModal}>
          <EstimateForm />
        </Modal>
      )}
    </>
  );
}
