import React from 'react';
import EstimateForm from './Estimate'; // Import EstimateForm here

const Modal = ({ children, onClose }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-transparent">
      <div className="absolute top-0 right-0 z-10 p-2">
      
      </div>
      <div className="bg-white text-gray-900 p-8 rounded-lg min-h-96 min-w-lg w-2/3 relative">
        <EstimateForm onClose={onClose} /> {/* Render EstimateForm inside Modal */}
        <button onClick={onClose} className="absolute top-2 right-0 bg-white border mr-2 border-gray-400 GtRegularBold text-gray-900 text-sm px-2 py-1 rounded-lg focus:outline-none">x</button>
      </div>
    </div>
  );
};

export default Modal;
