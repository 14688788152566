import React from "react";

const PortfolioCard = ({ project }) => {
  const { imageSrc, title, description } = project;

  return (
    <div className="flex rounded-lg GtRegular overflow-hidden mx-auto gap-2">
      <div className="w-full h-56 flex rounded-lg flex-col">
        <img
          className="h-44 w-44 rounded-lg object-cover object-center"
          src={imageSrc}
          alt={title}
        />
        <div className="p-4 -mt-4">
          <h3 className="text-l font-semibold text-gray-800">{title}</h3>
          <p className="text-sm text-gray-600">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default PortfolioCard;
