import React, { useState } from 'react';
import Modal from './modal'; // Adjust the path as needed

const EstimateForm = () => {
  const [formData, setFormData] = useState({
    first: '',
    last: '',
    email: '',
    phone: '',
    description: '',
    start: '',
    end: '',
    address: '',
  });

  const [currentStep, setCurrentStep] = useState(1);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://sheetdb.io/api/v1/flc0qcv4x02rz', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: formData }),
      });

      if (response.ok) {
        // Success: handle successful submission here
        setIsSubmitted(true);
        console.log('Form submitted successfully!');
      } else {
        // Error: handle submission error here
        console.error('Form submission error.');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleNext = (e) => {
    e.preventDefault();
    setCurrentStep((prevStep) => prevStep + 1);
  };
  const handleBack = (e) => {
    e.preventDefault();
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const renderFormContent = () => {
    if (isSubmitted) {
      return (
        <div className="text-center h-48 ">
          <h2 className="text-2xl GtRegularBold font-bold mb-4">Thank you for your submission!</h2>
          <p className="text-lg">We have received your information and will contact you shortly.</p>
        </div>
      );
    }

    switch (currentStep) {
      case 1:
        return (
          <>
            <div className="">
              <h2 className="GtRegularBold font-bold text-blue-700 mb-4 text-xl md:text-4xl -mt-8">Tell us about your project</h2>
              <p className="GtRegularBold mt-2 text-gray-600 mb-4 text-xl md:text-3xl -mt-8">
                Hello there, we are excited to hear about your painting project, we'll reach out to you as soon as possible!
              </p>

              <div className="mb-2">
                <label htmlFor="first" className="text-blue-700 GtRegularBold font-bold text-lg">First Name</label>
                <input
                  type="text"
                  id="first"
                  name="first"
                  placeholder="Enter your first name"
                  value={formData.first}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded focus:outline-none focus:border-LPBlue"
                />
              </div>

              <div className="mb-4">
                <label htmlFor="last" className="text-blue-700 GtRegularBold font-bold text-lg">Last Name</label>
                <input
                  type="text"
                  id="last"
                  name="last"
                  placeholder="Enter your last name"
                  value={formData.last}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded focus:outline-none focus:border-LPBlue"
                />
              </div>

              <div className="mb-4">
                <label htmlFor="email" className="text-blue-700 GtRegularBold font-bold text-lg">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email address"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded font-mono focus:outline-none focus:border-LPBlue"
                />
              </div>

              <div className="mb-8">
                <label htmlFor="phone" className="text-blue-700 GtRegularBold font-bold text-lg">Phone Number</label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  placeholder="phone number"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded font-mono focus:outline-none focus:border-LPBlue"
                />
              </div>

              <button
                type="button"
                onClick={handleNext}
                className="text-white bg-blue-500 rounded-xl text-2xl GtRegularBold font-bold px-6 py-2"
              >
                Next
              </button>
            </div>
          </>
        );

      case 2:
        return (
          <>
            <div className="">
              <h2 className="GtRegularBold font-bold text-blue-700 mb-4 text-xl md:text-4xl -mt-8">Project Details</h2>
              <p className="GtRegularBold mt-2 text-gray-600 mb-4 text-xl md:text-3xl -mt-8">
                Our estimators will contact you as soon as possible.
              </p>

              <div className="mb-2">
                <label htmlFor="first" className="text-blue-700 GtRegularBold font-bold text-lg">Project description</label>
                <textarea
                id="description"
                name="description"
                placeholder="Example: Looking to paint the exterior of my house, 2500sqft, stucco"
                value={formData.description}
                onChange={handleChange}
                className="w-full h-12 px-4 py-2 border rounded font-mono focus:outline-none focus:border-LPBlue"
              ></textarea>
              </div>

              <div className="mb-4">
              <label htmlFor="start" className="text-blue-700 GtRegularBold font-bold text-lg">Start Date</label>
                <input
                  type="date"
                  id="start"
                  name="start"
                  value={formData.start}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded font-mono focus:outline-none focus:border-LPBlue"
                />
              </div>

              <div className="mb-4">
              <label htmlFor="end" className="text-blue-700 GtRegularBold font-bold text-lg">End Date</label>
                <input
                  type="date"
                  id="end"
                  name="end"
                  value={formData.end}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded font-mono focus:outline-none focus:border-LPBlue"
                />
              </div>

              <div className="mb-8">
              <label htmlFor="address" className="text-blue-700 GtRegularBold font-bold text-lg">Address</label>
              <textarea
                id="address"
                name="address"
                placeholder="Enter the address of the project.."
                style={{ height: '50px' }}
                value={formData.address}
                onChange={handleChange}
                className="w-full px-4 py-2 border font-mono rounded focus:outline-none focus:border-LPBlue"
              ></textarea>
            </div>

              <div className="flex justify-between">
              <button
                type="button"
                onClick={handleBack}
                className="text-white bg-blue-500 text-2xl GtRegularBold rounded-xl font-bold px-6 py-2"
              >
                Back
              </button>
              <button
                type="submit"
                className="text-white bg-blue-500 text-2xl GtRegularBold font-bold rounded-xl px-6 py-2 focus:outline-none"
              >
                Submit
              </button>
            </div>
            </div>
          </>
        );


      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto h-full py-10">
      <form onSubmit={handleSubmit} className="mx-auto">
        {renderFormContent()}
      </form>
    </div>
  );
};

export default EstimateForm;
